import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import '../i18n';
import { withNamespaces } from 'react-i18next';
import Corporate from "./Corporate";
import estradosImage from "../images/estrados_500x325.png";
import placasImage from "../images/placas_500x325.png";
import telasImage from "../images/telas_500x325.png";
import utensiliosImage from "../images/utensilios_500x325.png";

const mapStateToProps = state => {
    return { language: state.language, families: state.families }
};

class Families extends Component {
  render() {
    const { t, language } = this.props;

    return (
      <div>
        <Corporate/>
        <div className="row text-center">
          {this.props.families.map(family => {
            let image = '';
            switch(family.id) {
              case 1:
                image = utensiliosImage;
                break;

              case 2:
                image = estradosImage;
                break;

              case 3:
                image = placasImage;
                break;

              case 4:
                image = telasImage;
                break;

              default:
                image = '';
            }

            return (
              <div className="col-lg-3 col-md-6 mb-4" key={family.id}>
                <div className="card">
                  <img className="card-img-top" src={image} alt=""/>
                  <div className="card-body">
                    <h4 className="card-title">
                      {(() => {
                        switch (language) {
                          case 'en':
                            return family.nameEN;
                          case 'es':
                            return family.nameES;
                          case 'fr':
                            return family.nameFR;
                          default:
                            return family.namePT;
                        }
                      })()}
                    </h4>
                    <p className="card-text">
                      {(() => {
                        switch (language) {
                          case 'en':
                            return family.descriptionEN;
                          case 'es':
                            return family.descriptionES;
                          case 'fr':
                            return family.descriptionFR;
                          default:
                            return family.descriptionPT;
                        }
                      })()}
                    </p>
                  </div>
                  <div className="card-footer">
                    <Link className='btn btn-primary' to={(language ? '/' + language : '') + '/' + family.slugEN + '__' + family.id}>
                      {t('seeFamily')}
                    </Link>
                  </div>
                </div>
              </div>
          )
        })}
        </div>
      </div>
    );
  }
}

const ConnectedFamilies = connect(mapStateToProps)(Families);

export default withNamespaces()(ConnectedFamilies);
