import {SET_CATEGORIES, SET_FAMILIES, SET_LANGUAGE, SET_PRODUCT_IMAGES, SET_PRODUCTS, SET_CONTENTS} from '../actions/types'

const initialState = {
  language: '',
  families: [],
  categories: [],
  products: [],
  productImages: [],
  content: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      let language = action.payload;
      if (language.length < 2) {
        language = '';
      }

      return {...state, language: language};

    case SET_FAMILIES:
      let families = action.payload;

      return {...state, families: families};

    case SET_CATEGORIES:
      let categories = action.payload;

      return {...state, categories: categories};

    case SET_PRODUCTS:
      let products = action.payload;

      return {...state, products: products};

    case SET_PRODUCT_IMAGES:
      let productImages = action.payload;

      return {...state, productImages: productImages};

    case SET_CONTENTS:
      let contents = action.payload;

      return {...state, contents: contents};

    default:
      return state;
  }
};

export default reducer;
