import React, { Component } from 'react';
import {Grid, Jumbotron} from "react-bootstrap";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";

const mapStateToProps = state => {
  return { language: state.language, contents: state.contents }
};

class Corporate extends Component {
  render() {
    const { language } = this.props;

    let content = this.props.contents.filter(content => {
      return content.contentSlug === 'company';
    });

    switch(language) {
      case 'en':
        content = content[0].contentEN;
        break;

      case 'es':
        content = content[0].contentES;
        break;

      case 'fr':
        content = content[0].contentFR;
        break;

      default:
        content = content[0].contentPT;
    }

    return (
      <div>
        <Jumbotron>
          <Grid>
            <p className="lead" dangerouslySetInnerHTML={{__html: content}} />
          </Grid>
        </Jumbotron>
      </div>
    );
  }
}

const ConnectedCorporate = connect(mapStateToProps)(Corporate);

export default withNamespaces()(ConnectedCorporate);
