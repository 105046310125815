import React, { Component } from 'react';
import {Col, Grid, Jumbotron, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";

const mapStateToProps = state => {
  return { language: state.language }
};

class Contacts extends Component {
  render() {
    const { t } = this.props;

    return (
      <Jumbotron className="contacts">
        <Grid>
          <Row><Col xs={12} md={12}><h1>{t('contacts')}</h1></Col></Row>
          <Row><Col xs={12} md={12}><p className="lead">{t('address')}:</p></Col></Row>
          <Row>
            <Col xs={12} md={12} className="inset">
              Zona Industrial Lote 17<br/>
              2430-600 Vieira de Leiria<br/>
              <a href="https://www.google.com/maps/place/MILQUES-Ind.+de+Plasticos,+Lda/@39.8646115,-8.9263857,17z/data=!4m5!3m4!1s0xd22115f417e4f33:0x499198c573ccf089!8m2!3d39.8668789!4d-8.9255762" target="_blank" rel="noopener noreferrer">
                {t('map')}
              </a>
            </Col>
          </Row>
          <Row><Col xs={12} md={12}><p className="lead">{t('phones')}:</p></Col></Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('phone')}:</Col>
            <Col>+351 244 698 030</Col>
          </Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('fax')}:</Col>
            <Col>+351 244 698 039</Col>
          </Row>
          <Row><Col xs={12} md={12}><p className="lead">{t('email')}:</p></Col></Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('general')}:</Col>
            <Col>geral@milques.pt</Col>
          </Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('sales')}:</Col>
            <Col>comercial@milques.pt</Col>
          </Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('finance')}:</Col>
            <Col>financeiro@milques.pt</Col>
          </Row>
          <Row>
            <Col xs={2} md={2} className="contact-title">{t('administration')}:</Col>
            <Col>administracao@milques.pt</Col>
          </Row>
          <Row><Col xs={12} md={12}><p className="lead">{t('schedule')}:</p></Col></Row>
          <Row>
            <Col xs={12} md={12} className="inset">
              {t('schedule1')} 9h {t('schedule2')} 13h {t('schedule3')} 14h {t('schedule4')} 18h
            </Col>
          </Row>
        </Grid>
      </Jumbotron>
    );
  }
}

const ConnectedContacts = connect(mapStateToProps)(Contacts);

export default withNamespaces()(ConnectedContacts);
