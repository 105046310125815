import React, {Component} from 'react';
import {connect} from 'react-redux';

import '../i18n';
import {withNamespaces} from 'react-i18next';
import {Row, Grid, Col} from "react-bootstrap";
import ProductImages from "./ProductImages";
import {Link} from 'react-router-dom';

const mapStateToProps = state => {
  return {language: state.language, products: state.products, categories: state.categories}
};

class Products extends Component {
  constructor(props) {
    super(props);

    this.handleChangeCategory = this.handleChangeCategory.bind(this);
  }

  handleChangeCategory(event) {
    const language = this.props.match.params.language;
    const family = this.props.match.params.family;

    let route = (language ? '/' + language : '') + '/' + family;
    if (event.target.value !== '') {
      route += '/' + event.target.value;
    }

    window.location = route;
  }

  render() {
    const {t, language} = this.props;
    const family = parseInt(this.props.match.params.family.split('__')[1]);

    let categoryId = 0;
    if (this.props.match.params.category) {
      categoryId = parseInt(this.props.match.params.category.split('__')[1]);
    }

    let products = this.props.products.filter(product => {
      const productFamily = parseInt(product.category.family.replace('/api/families/', ''), 10);
      const productCategory = product.category.id;
      const isSameFamily = productFamily === family;
      const isCategoryNotDefined = categoryId <= 0;
      const isSameCategory = productCategory === categoryId;

      return isSameFamily && (isCategoryNotDefined || isSameCategory);
    });

    let familyCategories = [];
    if (family > 0) {
      familyCategories = this.props.categories
        .filter(category => parseInt(category.family.replace('/api/families/', ''), 10) === family);
    }

    const rows = [...Array(Math.ceil(products.length / 4))];
    const productRows = rows.map((row, idx) => products.slice(idx * 4, idx * 4 + 4));
    const content = productRows.map((row, idx) => (
      <Row key={idx}>
        {row.map(product =>
          <Col xs={3} md={3} className='mb-4' key={product.id}>
            <Link to={(language ? '/' + language : '') + '/p/' + product.slugEN + '__' + product.id}>
              <div className="card product">
                <ProductImages className='card-img-top' imageId={product.images[0]}/>
                <div className="card-body">
                  <h4 className="card-title">
                    {(() => {
                      switch (language) {
                        case 'en':
                          return product.titleEN;
                        case 'es':
                          return product.titleES;
                        case 'fr':
                          return product.titleFR;
                        default:
                          return product.titlePT;
                      }
                    })()}
                  </h4>
                  <p className="card-text">
                    {(() => {
                      switch (language) {
                        case 'en':
                          return product.shortDescriptionEN;
                        case 'es':
                          return product.shortDescriptionES;
                        case 'fr':
                          return product.shortDescriptionFR;
                        default:
                          return product.shortDescriptionPT;
                      }
                    })()}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        )}
      </Row>
    ));

    return (
      <Grid>
        <Row>
          <Col xs={9} md={9}>
            <h1>{t('products')}</h1>
          </Col>
          <Col xs={3} md={3}>
            <p>{t('category')}</p>
            <select onChange={this.handleChangeCategory} value={this.props.match.params.category}>
              <option value="">{t('allCategories')}</option>
              {familyCategories.map(category => {
                let slug = '';
                let name = '';
                switch (language) {
                  case 'en':
                    name = category.nameEN;
                    slug = category.slugEN;
                    break;
                  case 'es':
                    name = category.nameES;
                    slug = category.slugES;
                    break;
                  case 'fr':
                    name = category.nameFR;
                    slug = category.slugFR;
                    break;
                  default:
                    name = category.namePT;
                    slug = category.slugPT;
                }
                return (
                  <option key={category.id} value={slug + '__' + category.id}>
                    {name}
                  </option>
                )
              })}
            </select>
          </Col>
        </Row>
        {content}
      </Grid>
    );
  }
}

const ConnectedProducts = connect(mapStateToProps)(Products);

export default withNamespaces()(ConnectedProducts);
