import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

const mapStateToProps = state => {
  return { productImages: state.productImages }
};

class ProductImages extends Component {
  render() {
    const { imageId, className } = this.props;
    const image = this.props.productImages[imageId];

    return (
      <img width='100%' className={className} alt='' src={process.env.REACT_APP_API_URL + '/images/products/' + image.contentUrl}/>
    );
  }
}

const ConnectedProductImages = connect(mapStateToProps)(ProductImages);

export default withNamespaces()(ConnectedProductImages);
