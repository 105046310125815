import React, { Component }  from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { Nav, NavDropdown, Navbar, NavItem, MenuItem } from 'react-bootstrap';
import Families from './components/Families';
import Products from './components/Products';
import Product from './components/Product';
import Contacts from './components/Contacts';
import { withRouter } from "react-router";
import './i18n';
import { withNamespaces } from 'react-i18next';
import store from "./store";
import {setCategories, setContents, setFamilies, setProductImages, setProducts} from "./actions";
import axios from "axios";
import logo from "./images/logo.gif";
import {connect} from "react-redux";

const mapStateToProps = state => {
  return { language: state.language }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentWillMount() {
    this.setState({loading: true});

    const apiUrl = process.env.REACT_APP_API_URL;

    let familiesPromise = new Promise(function(resolve, reject) {
      axios.get(apiUrl + '/api/families.json')
        .then((response) => {
          store.dispatch(setFamilies(response.data));
          resolve();
        }).catch((ex) => {
        reject({message: 'parsing families failed', exception: ex});
      });
    });

    let categoriesPromise = new Promise(function(resolve, reject) {
      axios.get(apiUrl + '/api/categories.json')
        .then((response) => {
          store.dispatch(setCategories(response.data));
          resolve();
        }).catch((ex) => {
        reject({message: 'parsing categories failed', exception: ex});
      });
    });

    let productsPromise = new Promise(function(resolve, reject) {
    axios.get(apiUrl + '/api/products.json')
      .then((response) => {
        store.dispatch(setProducts(response.data));
        resolve();
      }).catch((ex) => {
        reject({message: 'parsing products failed', exception: ex});
      });
    });

    let productImagesPromise = new Promise(function(resolve, reject) {
      axios.get(apiUrl + '/api/product_images.json')
        .then((response) => {
          let productImages = {};
          for (let i = 0; i < response.data.length; i++) {
            const image = response.data[i];

            productImages['/api/product_images/' + image.id] = image;
          }

          store.dispatch(setProductImages(productImages));
          resolve();
        }).catch((ex) => {
        reject({message: 'parsing product images failed', exception: ex});
      });
    });

    let contentsPromise = new Promise(function(resolve, reject) {
      axios.get(apiUrl + '/api/contents.json')
        .then((response) => {
          store.dispatch(setContents(response.data));
          resolve();
        }).catch((ex) => {
        reject({message: 'parsing contents failed', exception: ex});
      });
    });

    Promise
      .all([familiesPromise, categoriesPromise, productsPromise, productImagesPromise, contentsPromise])
      .then(() => {this.setState({loading: false});})
      .catch(error => {
        console.log(error.message)
      });
  }

  render() {
    const { t, language } = this.props;
    const { loading } = this.state;
    const languageTranslation = t('language');

    let main = 'Loading';
    if (!loading) {
      main =
        <Switch>
          <Route exact path='/' component={Families} />
          <Route exact path='/:language(pt|es|en|fr)' component={Families} />
          <Route exact path='/:language(pt|es|en|fr)/contacts' component={Contacts} />
          <Route exact path='/contacts' component={Contacts} />
          <Route path='/:language(pt|es|en|fr)/:family([^/]+__\d+)/:category?' component={Products} />
          <Route path='/:family([^/]+__\d+)/:category?' component={Products} />
          <Route path='/:language(pt|es|en|fr)/p/:product([^/]+__\d+)' component={Product} />
          <Route path='/p/:product([^/]+__\d+)' component={Product} />
        </Switch>;
    }

    const contactsUrl = (language ? '/' + language : '') + '/contacts';

    return (
      <div className='container'>
        <Navbar fixedTop>
          <Navbar.Brand className='logo'>
            <Link to='/'><img src={logo} alt="Milques"/></Link>
          </Navbar.Brand>
          <Nav pullRight>
            <NavItem eventKey={1} href={contactsUrl}>{t('contacts')}</NavItem>
            <NavDropdown eventKey={2} title={languageTranslation} id='basic-nav-dropdown'>
              <MenuItem eventKey={2.1} href='/'>PT</MenuItem>
              <MenuItem eventKey={2.2} href='/en'>EN</MenuItem>
              <MenuItem eventKey={2.3} href='/es'>ES</MenuItem>
              <MenuItem eventKey={2.4} href='/fr'>FR</MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar>

        {main}

        <footer className='py-5 bg-dark'>
          <div className='container'>
            <p className='m-0 text-center text-white'>Copyright &copy; Milques 2019</p>
          </div>
        </footer>
      </div>
    );
  }
}

const ConnectedApp = connect(mapStateToProps)(App);

export default withNamespaces()(withRouter(ConnectedApp));
