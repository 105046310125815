import 'react';
import i18n from 'i18next';
import { reactI18nextModule } from "react-i18next";
import {setLanguage} from "./actions";
import store from './store/index'

i18n
  .use(reactI18nextModule)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: 'pt',
    // Using simple hardcoded resources for simple example
    resources: {
      pt: {
        translation: {
          language: 'Língua',
          contacts: 'Contactos',
          family: 'Família',
          chooseFamily: 'Escolha uma família',
          category: 'Filtrar por categoria',
          allCategories: 'Todas',
          products: 'Produtos',
          seeFamily: 'Ver produtos',
          any: 'Qualquer',
          back: 'Voltar',
          address: 'Endereço',
          map: 'Mapa',
          phones: 'Telefones',
          phone: 'Telefone',
          fax: 'Fax',
          email: 'Email',
          general: 'Geral',
          sales: 'Comercial',
          finance: 'Financeiro',
          administration: 'Administração',
          schedule: 'Horário',
          schedule1: 'Das',
          schedule2: 'às ',
          schedule3: 'e das',
          schedule4: 'às',
        },
      },
      en: {
        translation: {
          language: 'Language',
          contacts: 'Contacts',
          family: 'Family',
          chooseFamily: 'Choose a family',
          category: 'Category filter',
          allCategories: 'All',
          products: 'Products',
          seeFamily: 'See products',
          any: 'Any',
          back: 'Back',
          phones: 'Telephones',
          phone: 'Telephone',
          fax: 'Fax',
          email: 'Email',
          general: 'General',
          sales: 'Commercial',
          finance: 'Finance',
          administration: 'Administration',
          schedule: 'Working hours',
          schedule1: 'From',
          schedule2: 'to',
          schedule3: 'and from',
          schedule4: 'to',
          map: 'Map',
          address: 'Address',
        },
      },
      es: {
        translation: {
          language: 'Idioma',
          contacts: 'Contactos',
          family: 'Familia',
          chooseFamily: 'Elige familia',
          category: 'Categoría',
          allCategories: 'Todas las categorias',
          products: 'Productos',
          seeFamily: 'Ver productos',
          any: 'Alguna',
          back: 'Volver',
          phones: 'Teléfonos',
          phone: 'Teléfono',
          fax: 'Fax',
          email: 'Correo electrónico',
          general: 'General',
          sales: 'Comercial',
          finance: 'Finanzas',
          administration: 'Administración',
          schedule: 'Horas de trabajo',
          schedule1: 'De',
          schedule2: 'a',
          schedule3: 'y desde',
          schedule4: 'a',
          map: 'Mapa',
          address: 'Dirección',
        },
      },
      fr: {
        translation: {
          language: 'Langue',
          contacts: 'Contacts',
          family: 'Famille',
          chooseFamily: 'Choisissez famille',
          category: 'Catégorie',
          allCategories: 'Toutes catégories',
          products: 'Produits',
          seeFamily: 'Voir produits',
          any: 'Tout',
          back: 'Retourner',
          phones: 'Téléphones',
          phone: 'Téléphone',
          fax: 'Fax',
          email: 'Email',
          general: 'Général',
          sales: 'Commercial',
          finance: 'Finance',
          administration: 'Administration',
          schedule: 'Heures de travail',
          schedule1: 'De',
          schedule2: 'à',
          schedule3: 'et de',
          schedule4: 'à',
          map: 'Carte',
          address: 'Adresse',
        },
      },
    },
    //wait: true,
  });

if (typeof window !== 'undefined') {
  var language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
  if (language instanceof Array) {
    let languageCode = language[0].replace('/', '');

    if (['pt', 'en', 'es', 'fr'].indexOf(languageCode) === -1) {
      languageCode = 'pt';
    }

    i18n.changeLanguage(languageCode);

    store.dispatch(setLanguage(languageCode));
  }
}
