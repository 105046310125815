import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../i18n';
import { withNamespaces } from 'react-i18next';
import { Grid, Row, Carousel, Col } from "react-bootstrap";
import ProductImages from "./ProductImages";

const mapStateToProps = state => {
  return {
    language: state.language,
    products: state.products,
    categories: state.categories,
    families: state.families
  }
};

class Product extends Component {
  render() {
    const { t, language } = this.props;
    const productId = parseInt(this.props.match.params.product.split('__')[1]);

    let product = this.props.products.filter(product => {
      return product.id === productId;
    });
    product = product[0];

    let family = this.props.families.filter(family => {
      return family.id === parseInt(product.category.family.replace('/api/families/', ''));
    });
    family = family[0];

    let imageColumn = '';
    if (product.images.length > 1) {
      imageColumn = (
        <Carousel>
          {product.images.map( image =>
            <Carousel.Item key={image}>
              <ProductImages imageId={image}/>
            </Carousel.Item>
          )}
        </Carousel>
      );
    } else {
      imageColumn = (
        <ProductImages imageId={product.images[0]}/>
      );
    }

    let description;

    switch(language) {
      case 'en':
        description = product.descriptionEN;
        break;

      case 'es':
        description = product.descriptionES;
        break;

      case 'fr':
        description = product.descriptionFR;
        break;

      default:
        description = product.descriptionPT;
    }

    description = description.split("\r\n\r\n").map((item, i) => {
      item = item.split("\r\n").map((innerItem, j) => {
        return <span key={j}>{innerItem}<br/></span>;
      });

      return <p key={i}>{item}</p>;
    });

    let backUrl = (language ? '/' + language : '') + '/' + family.slugEN + '__' + family.id;

    return (
      <div>
        <div>
          <h1>
            {(() => {
              switch(language) {
                case 'en':
                  return product.titleEN;
                case 'es':
                  return product.titleES;
                case 'fr':
                  return product.titleFR;
                default:
                  return product.titlePT;
              }
            })()}
          </h1>
          <a href={backUrl}>{t('back')}</a>
        </div>
        <Grid>
          <Row>
            <Col md={8} className='mb-4'>
              {imageColumn}
            </Col>
            <Col>
              {description}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const ConnectedProduct = connect(mapStateToProps)(Product);

export default withNamespaces()(ConnectedProduct);
